/*fonts*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Roboto:wght@400;500&display=swap");

body {
  background: #f0f0f0; /* Light grey background for contrast */
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background: #fff; /* White background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
  padding: 40px 50px; /* Increased padding for more space */
  width: 400px; /* Slightly wider */
  text-align: center;
  color: #333; /* Darker text for contrast */
}

.container h1,
.container span {
  font-family: "Montserrat", sans-serif;
}

.container h1 {
  color: #333; /* Darker color for the heading */
  font-weight: 600;
  font-size: 2em;
  margin-bottom: 10px;
}

.container h2 {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  color: #666; /* Grey color for subheading */
  font-size: 1.2em;
  margin-bottom: 20px;
}

form {
  margin-top: 25px;
}

input,
.MuiAutocomplete-root {
  margin-bottom: 20px;
  width: 100%;
}

input {
  border: 1px solid #ccc;
  outline: none;
  height: 40px;
  border-radius: 20px;
  padding: 0 18px;
  box-sizing: border-box;
  font-size: 1em;
  background-color: #fff;
}

input[type="submit"] {
  background: #007bff; /* Blue background for the submit button */
  color: #fff;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background 0.3s ease;
}

input[type="submit"]:hover {
  background: #0056b3; /* Darker blue on hover */
}

.MuiAutocomplete-root .MuiInputBase-root {
  border-radius: 20px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  border-radius: 20px;
  background: #fff;
  padding-left: 20px;
}

.MuiAutocomplete-inputRoot {
  padding-left: 20px !important;
}

.MuiInputLabel-outlined {
  padding-left: 20px;
}

.MuiInputLabel-shrink {
  margin-left: 20px;
  padding-left: 10px;
  padding-right: 0;
  background: white;
}

.loading {
  margin-top: 15px;
}
